import { GlobalDataContext } from "../../context/context";
import React, { useContext } from "react";
import { VscDebugBreakpointData } from 'react-icons/vsc'
import PrimaryButton from "../global/boton/PrimaryButton";
import { FiPhoneCall } from "react-icons/fi";




function Block_12({ image1, image2, title, subtitle2, text, listAbout, listServices, sloganPrincipal }) {

    const { rpdata } = useContext(GlobalDataContext);



    return (

        <section className="w-[90%] mx-auto md:py-32 py-0">
            <div className="md:flex md:flex-row-reverse flex flex-col-reverse md:p-0 px-2  relative">
                <div className="md:w-[50%] py-4 md:px-8 px-0 self-center md:text-start text-center" data-aos="fade-up" data-aos-duration="900">

                    <h5 className="separator-about capitalize text-[20px]">
                        {
                            title ? title :
                                <span className='lowercase text-[18px]'>
                                    {`default title={'frase'}`} o
                                    {` agregar licencia o años de experienceias sloganPrincipal={true}`}
                                </span>
                        }
                    </h5>


                    {
                        sloganPrincipal ?
                            rpdata?.dbPrincipal?.licensed ?
                                <h2 className='pb-3 capitalize'>
                                    {rpdata?.dbPrincipal?.licensed}
                                </h2>
                                : <h2 className='pb-5 capitalize'>
                                    {
                                        rpdata?.dbPrincipal?.exprYears ?
                                            `we have ${rpdata?.dbPrincipal?.exprYears} years of experience`
                                            : 'we have many years of experience'
                                    }
                                </h2>
                            :
                            <h3 className="separator-about capitalize font-bold">
                                {
                                    subtitle2 ? subtitle2 :
                                        <span className='lowercase text-[18px]'>
                                            {`default title={'frase'}`} o
                                            {` agregar licencia o años de experienceias sloganPrincipal={true}`}
                                        </span>
                                }
                            </h3>
                    }

                    <p className="pb-4">
                        {
                            text ? text :
                                <span>{`para agregar el texto -> text={'description'}`}
                                    <br />{'para agregar lista de about -> listsAbout={true}'}
                                    <br />{'para agregar lista de servicios -> listsServices={true}'}
                                </span>
                        }
                    </p>


                    <div className="flex md:flex-row flex-col items-center gap-4">

                        <div className="w-1/2 md:my-1 -mt-4 flex flex-col justify-start items-center pt-0">
                            {
                                listServices ?
                                    <ul className="grid grid-cols-1 md:grid-cols-1 pt-4">
                                        {rpdata?.dbServices?.slice(0, 4).map((item, index) => {
                                            return (
                                                <li key={index} className="py-1 flex items-center">
                                                    <VscDebugBreakpointData />
                                                    <span>{item.name}</span>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    : null
                            }
                            {
                                listAbout ?
                                    <ul className='grid grid-cols-1 md:grid-cols-1 pt-3'>
                                        {
                                            rpdata?.dbAbout?.[0].list.length > 1 ?
                                                rpdata?.dbAbout?.[0].list.map((item, index) => {
                                                    return (

                                                        <li key={index} className="py-2 flex items-center">
                                                            <VscDebugBreakpointData />
                                                            <span className="pl-2">{item}</span>
                                                        </li>
                                                    )
                                                })
                                                : null
                                        }
                                    </ul>
                                    : null
                            }

                        </div>
                        <div className="w-1/2 md:my-1 flex flex-col justify-start items-center pt-0">
                            <PrimaryButton />
                        </div>

                    </div>
                    <div className="w-full">
                        <span className="separator-about"></span>
                    </div>
                </div>


                <div className="w-full lg:w-1/2 md:h-auto h-[400px] lg:h-auto flex items-center justify-center relative">
                    <div className="lg:w-[95%] w-full h-auto relative">
                        <picture>
                            <img
                                src={image1}
                                alt="not-found"
                                className=" object-cover w-full rounded-[20px] h-[225px] lg:h-[450px]"
                            />
                        </picture>
                        <div className="w-32 h-32 top-0 left-0 absolute hidden md:flex lg:flex justify-center items-center">
                            <div className="w-20 h-20 rounded-full bg-secondary animate-ping transition ease-in-out delay-100 duration-700"></div>
                            <div className="w-16 h-16 rounded-full bg-secondary animate-ping transition ease-in-out delay-500 duration-75 absolute"></div>
                            <a
                                 href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`}
                                className='flex items-center justify-center absolute border border-black w-auto'
                            >
                                <div className="w-20 rounded-full h-20  bg-secondary absolute flex justify-center items-center">
                                    <FiPhoneCall fontSize={24} color="#fff" />
                                </div>
                            </a>
                        </div>
                    </div>

                    <img
                        width={200} height={200}
                        src={image2}
                        alt="imagen2"
                        className="lg:w-[300px] lg:h-[300px] w-[200px] h-[200px] rounded-full absolute lg:-right-5 right-[20%] lg:bottom-10 -bottom-3 border-white border-[18px]"
                    />
                </div>

            </div>
        </section>
    );
}

export default Block_12;